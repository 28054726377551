import React,{useState,useEffect} from 'react';
import styled from '@emotion/styled';
import {Quote,CommentBG} from '../img'
import mq from './../scripts/breakpoints';


const Container = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    ${mq[1]}{
        padding: 10rem;
    }
    img{
        margin: 3rem 0;
        width: 40px;
        height: 20%
    }

    .testimonial{
        width: 80%;
        height: 100px;
        margin-bottom: 5rem;
        text-align: center;
        ${mq[1]}{
            width: 50%;
            font-size: 1.2rem;
        }
    }

    .name{
        font-family: 'Cabin' ,sans-serif;
        height: 10%;
        font-size: 1.2rem;
    }
    

`;

const Comments = () => {

    const [seconds,setSeconds] = useState(0)
    // eslint-disable-next-line
    const [testimonials,setTestimonials] = useState([
        {
            testimonial: '"They are always so friendly & helpful every time I go there. I give them 5 stars & I recommend them 2 all my family & friends. Be Blessed Always."',    
            name: 'Sylvia S.'
        },
        {
            testimonial: '"Love there customer service always friendly and make things easy and convenient for you...nice place."',
            name: 'Melissa B.'
        },
        {
            testimonial: '"I agree the prices are good. They offer meat plan packages. Also they have the freshes meat in the area. Love the baby turkey wings. They are small and tender."',
            name: 'Belinda G.'
        }
    ])


    useEffect(() => {
        const interval = setInterval(() => {
          setSeconds((seconds)=>{
            if(seconds === 2){
                seconds = 0
                return seconds
            }
            return seconds + 1
          });
        }, 10000);
        return () => clearInterval(interval);
      }, []);

    return (
        <Container style={{backgroundImage: `url(${CommentBG})`,backgroundPosition: 'center'}}>
            <img src={Quote} alt=""/>
            <div className="testimonial animate__animated animate__fadeIn ">
                <div className="quote">
                    {testimonials[seconds].testimonial}
                </div>
                <div className="name">
                    {testimonials[seconds].name}
                </div>
            </div>
        </Container>
    );
};

export default Comments;