import React, { useState } from "react";
import styled from "@emotion/styled";
import mq from "./../scripts/breakpoints";
import Banner from "./../components/Banner";
import MetaData from "../components/MetaData";
import specials from "../data/specials.json";

import {
  SpecialBG,
  Cow,
  Tbone,
  Chicken,
  Bacon,
  Drumbstick,
  Fillet,
  Meat2,
  Pig,
  Pincho,
  Ribs,
  Sausage,
  Steak,
  Wholechicken,
  Add,
  Remove,
} from "../img";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  font-family: "Cabin", sans-serif;
  ${mq[1]} {
    margin-top: 0;
  }
  .main {
    ${mq[1]} {
      width: 100%;
    }
    .text {
      text-align: center;
      width: 90%;
      margin: auto;
      margin-bottom: 3rem;

      h3 {
        font-size: 1.5rem;
        color: #c13a3a;
      }
      p {
        font-family: Arial, Helvetica, sans-serif;
      }
      ${mq[1]} {
        width: 40%;
        h3 {
          font-size: 2rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }

    /* TODO
        -comments this section after superblow
    */

    .seasoning-special {
      background-color: #c13a3a;
      width: 80%;
      margin: auto;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 20px 0px;
      color: white;
      ${mq[1]} {
        width: 32%;
      }
    }
    .specials-alert {
      width: 70%;
      margin: auto;
      text-align: center;
      border: solid 1px #c13a3a;
      padding: 10%;
      color: #c13a3a;
      margin-bottom: 100px;
      h2 {
        font-weight: bold;
      }
      img {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 90px;
      }
      ${mq[1]} {
        width: 32%;
      }
      .price {
        font-weight: bold;
        font-family: "Alfa Slab One", sans-serif;
        font-size: 1.8rem;
        color: #c13a3a;
      }
    }
    /* END OF TODO */
    .everyday-specials-container {
      margin-bottom: 2.5rem;
      max-width: 80%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      ${mq[1]} {
        width: 50%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      h3 {
        font-size: 1.5rem;
        color: #c13a3a;
        margin-bottom: 1.5rem;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        border: solid 1px #c13a3a;
        margin-bottom: 2.5rem;
        thead {
          background-color: #c13a3a;
          color: white;
          th {
            padding: 10px;
            text-align: center; // added text-align center
          }
        }
        tbody {
          tr {
            td {
              padding: 10px;
              text-align: center; // added text-align center
            }
          }
        }
      }
    }
    td {
      padding: 10px;
      text-align: center;
      border: solid 1px #c13a3a;
    }
    .specials-container {
      width: 100%;
      ${mq[1]} {
        width: 50%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .special {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: auto;
      margin-bottom: 2.5rem;
      ${mq[1]} {
        width: 32%;
      }
      img {
        width: 60px;
      }
      .name {
        margin: 0.5rem 0;
        width: 100%;
        border: solid 1px #c13a3a;
        color: #c13a3a;
        font-weight: bold;
        font-family: "Cabin", sans-serif;
        display: flex;
        justify-content: space-around;
        align-items: center;
        p {
          margin-right: 0.8rem;
          margin: 0.5rem 0 0.5rem 0;
        }
        span img {
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .price {
        font-weight: bold;
        font-family: "Alfa Slab One", sans-serif;
        font-size: 1.8rem;
        color: #c13a3a;
      }
      .list {
        border-bottom: solid #eee 1px;
        display: none;
        p {
        }
        ul {
          li {
            font-family: "Arial";
            font-size: 0.9rem;
          }
        }
      }
    }
  }
`;

const Specials = () => {
  const [isDetail, setDetail] = useState(true);

  const show = (e) => {
    const target =
      e.target.parentElement.parentElement.nextElementSibling
        .nextElementSibling;

    target.style.display = "block";
    target.classList.add("animate__animated", "animate__fadeIn");
    setDetail(false);
  };

  const hide = (e) => {
    const target =
      e.target.parentElement.parentElement.nextElementSibling
        .nextElementSibling;

    target.style.display = "none";

    setDetail(true);
  };

  const everydaySpecials = [
    {
      name: "10lbs - 1/4 legs",
      price: "$10",
    },
    {
      name: "10lbs - chicken wings",
      price: "$48.99",
    },
    {
      name: "5lbs - chicken cullets",
      price: "$24.99",
    },
    {
      name: "10lbs - chicken drums",
      price: "$13.99",
    },
    {
      name: "10lbs - turkey wings",
      price: "$55.99",
    },
    {
      name: "3lbs - ground beef",
      price: "$14.99",
    },
    {
      name: "5lbs - end cut pork chops",
      price: "$10.99",
    },
    {
      name: "5lbs - center cut pork chops",
      price: "$12.99",
    },
    {
      name: "3lbs - bacon",
      price: "$14.99",
    },
    {
      name: "10lbs - pork spared ribs",
      price: "$48.99",
    },
    {
      name: "10lbs - pigs feet",
      price: "$19.99",
    },
  ];

  return (
    <Container>
      <MetaData
        title={`Perez & Almonte - ${specials.title}`}
        description={specials.description}
      />
      <Banner img={SpecialBG} title="SPECIALS" />
      <div className="main">
        <div className="seasoning-special">Seasoning Included FREE</div>
        <div className="seasoning-special">
          Ask us for our Meats to Grill, Best Quality In the Market
        </div>
        <div className="text">
          <h3>Our Specials</h3>
          <p>
            We offer only the best in USDA Prime beef and meat selections. It's
            the same quality you've come to know and love for over years.
          </p>
        </div>
        {/* TODO
            -Comment specials alert after superbowl
        */}
        {/* <div className="specials-alert">
          <h2>PARTY WINGS</h2>
          <h4>(Limited Time)</h4>
          <img src={Chicken} alt="" />
          <div className="price">
            $5.49<span> lbs</span>
          </div>
          <p>OR</p>
          <div className="price">
            $49.99<span> 10lbs</span>
          </div>
        </div> */}
        {/* <div className="specials-alert">
          <h2>WEEKEND SPECIAL</h2>
          <h4>(11/18/22 - 11/20/22)</h4>
          <h3>Chicken Wings 10Lbs</h3>
          <img src={Chicken} alt="" />
          <div className="price">
            $38.99<span> 10lbs</span>
          </div>
          <h4>You Save $10</h4>
          <h6>The Deal Only Apply For 10LBS</h6>
        </div> */}
        <div className="everyday-specials-container">
          <h3>Everyday Specials</h3>

          <table>
            <thead>
              <tr>
                <th>Special</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {everydaySpecials.map((special, index) => (
                <tr key={index}>
                  <td>{special.name}</td>
                  <td>{special.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="specials-container">
          <div className="special">
            <img src={Cow} alt="" />
            <div className="name">
              <p>Meat Plan #1</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$39.99</div>
            <div className="list">
              <p>FREE : 1 Dozen eggs</p>
              <ul>
                <li>2 lbs. Chicken 1/4 Legs</li>
                <li>2 lbs. Chicken Drums</li>
                <li>2 lbs. Turkey Wings</li>
                <li>2 lbs. End Cut Pork Chops</li>
                <li>2 lbs. Pork Ribs</li>
                <li>2 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Tbone} alt="" />
            <div className="name">
              <p>Meat Plan #2</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$52.99</div>
            <div className="list">
              <p>FREE: 1 Dozen Eggs</p>
              <ul>
                <li>4 lbs. Chicken 1/4 Legs</li>
                <li>2 lbs. Chicken Drums</li>
                <li>2 lbs. Chicken Wings</li>
                <li>2 lbs. Turkey Wings</li>
                <li>2 lbs. End Cut Porkchops</li>
                <li>1 lbs. Slab Bacon</li>
                <li>2 lbs. Smoked Neck Bones</li>
                <li>2 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Chicken} alt="" />
            <div className="name">
              <p>Meat Plan #3</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$73.99</div>
            <div className="list">
              <p>FREE: 1 Dozen Eggs</p>
              <ul>
                <li>3 lbs. Chicken 1/4 Legs</li>
                <li>2 lbs. Chicken Wings</li>
                <li>1 Whole Chicken</li>
                <li>3 lbs. Turkey Wings</li>
                <li>2 lbs. Beef Stew</li>
                <li>2 lbs. Beef Liver</li>
                <li>2 lbs. Chuck Steak</li>
                <li>2 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Drumbstick} alt="" />
            <div className="name">
              <p>Meat Plan #4</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$73.99</div>
            <div className="list">
              <p>FREE: 2L Soda</p>
              <ul>
                <li>3 lbs. Chicken 1/4 Legs</li>
                <li>2 lbs. Chicken Wings</li>
                <li>3 lbs. Turkey Wings</li>
                <li>2 lbs. End Cut Pork Chop</li>
                <li>2 lbs. Smoked Neck Bones</li>
                <li>2 lbs. Cubed Steak</li>
                <li>2 lbs. Chuck Steak</li>
                <li>2 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Pig} alt="" />
            <div className="name">
              <p>Meat Plan #5</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$89.99</div>
            <div className="list">
              <p>FREE 1/2 Gallon Milk</p>
              <ul>
                <li>3 lbs. Chicken 1/4 Legs</li>
                <li>2 lbs. Chicken Wings</li>
                <li>1 Whole Chicken</li>
                <li>3 lbs. Turkey Wings</li>
                <li>3 lbs. Pig's Feet</li>
                <li>2 lbs. Pork Chops</li>
                <li>1 lb. Slab Bacon</li>
                <li>2 lbs. Beef Liver</li>
                <li>2 lbs. Beef Stew</li>
                <li>2 lbs. Chuck Steak</li>
                <li>2 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Sausage} alt="" />
            <div className="name">
              <p>Meat Plan #6</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$69.99</div>
            <div className="list">
              <p>FREE: 1 Dozen Eggs</p>
              <ul>
                <li>3 lbs. Chicken 1/4 Legs</li>
                <li>3 lbs. Chicken Wings</li>
                <li>1 Whole Chicken</li>
                <li>2 lbs. Turkey Wings</li>
                <li>2 lbs. End Cut Pork Chops</li>
                <li>2 lbs. Beef Stew</li>
                <li>2 lbs. Chuck Steak</li>
                <li>2 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Fillet} alt="" />
            <div className="name">
              <p>Meat Plan #7</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$95.99</div>
            <div className="list">
              <p>FREE: 1 Dozen Eggs</p>
              <ul>
                <li>4 lbs. Chicken 1/4 Legs</li>
                <li>3 lbs. Chicken Drums</li>
                <li>3 lbs. Chicken Wings</li>
                <li>3 lbs. Turkey Wings</li>
                <li>2 lbs. Beef Steak</li>
                <li>2 lbs. Beef Stew</li>
                <li>3 lbs. Chuck Steak</li>
                <li>3 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Ribs} alt="" />
            <div className="name">
              <p>Meat Plan #8</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$99.99</div>
            <div className="list">
              <p>FREE: 1/2 Gallon Milk</p>
              <ul>
                <li>3 lbs. Chicken 1/4 Legs</li>
                <li>3 lbs. Chicken Wings</li>
                <li>3 lbs. Turkey Wings</li>
                <li>3 lbs. End Cut Pork Chops</li>
                <li>2 lbs. Smoked Neck Bones</li>
                <li>2 lbs. Beef Liver</li>
                <li>3 lbs. Chuck Steak</li>
                <li>2 lbs. Shoulder Steak</li>
                <li>3 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Steak} alt="" />
            <div className="name">
              <p>Meat Plan #9</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$115.99</div>
            <div className="list">
              <p>FREE: 1/2 Gallon Milk</p>
              <ul>
                <li>2 lbs. Chicken Drumsticks</li>
                <li>3 lbs. Chicken Wings</li>
                <li>One Whole Chicken</li>
                <li>3 lbs. Turkey Wings</li>
                <li>3 lbs. End Cut Pork Chops</li>
                <li>3 lbs. Pork Spare Ribs</li>
                <li>3 lbs. Beef Liver</li>
                <li>3 lbs. Chuck Steak</li>
                <li>2 lbs. Cubed Steak</li>
                <li>3 lbs. Ground Beff</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Meat2} alt="" />
            <div className="name">
              <p>Meat Plan #10</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$115.99</div>
            <div className="list">
              <p>FREE: 1 Gallon Milk</p>
              <ul>
                <li>2 lbs. Chicken Breast</li>
                <li>3 lbs. Chicken Drumsticks</li>
                <li>3 lbs. Chicken Wings</li>
                <li>3 lbs. Turkey Wings</li>
                <li>2 lbs. Slab Bacon</li>
                <li>2 lbs. Smoked Neck Bones</li>
                <li>3 lbs. Center Cut Porkchops</li>
                <li>2 lbs. Beef Stew</li>
                <li>3 lbs. Chuck Steak</li>
                <li>3 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Bacon} alt="" />
            <div className="name">
              <p>Meat Plan #11</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$125.99</div>
            <div className="list">
              <p>FREE: One Dozen Eggs</p>
              <ul>
                <li>3 lbs. Chicken Wings</li>
                <li>Two Hole Chickens</li>
                <li>3 lbs. Turkey Wings</li>
                <li>3 lbs. Center Cut Pork Chops</li>
                <li>4 lbs. Pig's Feet</li>
                <li>3 lbs. Pork Spare Ribs</li>
                <li>2 lbs. Beef Steak</li>
                <li>3 lbs. Chuck Steak</li>
                <li>3 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Pincho} alt="" />
            <div className="name">
              <p>Meat Plan #12</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$135.99</div>
            <div className="list">
              <p>FREE: One Dozen Egss</p>
              <ul>
                <li>4 lbs. Chicken 1/4 Legs</li>
                <li>3 lbs. Chicken Wings</li>
                <li>3 lbs. Turkey Wings</li>
                <li>3 lbs. End Cut Pork Chops</li>
                <li>3 lbs. Pork Spare Ribs</li>
                <li>2 lbs. Slab Bacon</li>
                <li>3 lbs. Beef Liver</li>
                <li>2 lbs. Beef Stew</li>
                <li>2 lbs. Chuck Steak</li>
                <li>2 lbs. Shoulder Steak</li>
                <li>2 lbs. Lamb Chops</li>
                <li>2 lbs. Smoked Sausage</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Wholechicken} alt="" />
            <div className="name">
              <p>Meat Plan #13</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$179.99</div>
            <div className="list">
              <p>FREE: One Dozen Eggs & 5 lb Bag Of Potatos</p>
              <ul>
                <li>5 lbs. Chicken Wings</li>
                <li>3 lbs. Chicken Drumsticks</li>
                <li>3 lbs. Chicken Wings</li>
                <li>One Whole Chicken</li>
                <li>2 lbs. Smoked Turkey Wings</li>
                <li>3 lbs. Turkey Wings</li>
                <li>2 lbs. End Cut Pork Chops</li>
                <li>3 lbs. Fresh Neck Bones</li>
                <li>3 lbs. Pig's Feet</li>
                <li>3 lbs. Pork Spare Ribs</li>
                <li>1 lb. Slab Bacon</li>
                <li>3 lbs. Smoked Neck Bones</li>
                <li>3 lbs. Beef Liver</li>
                <li>2 lbs. Chuck Steak</li>
                <li>3 lbs. Ground Beef</li>
                <li>2 lbs. Shoulder Steak</li>
                <li>3 lbs. Short Ribs</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Wholechicken} alt="" />
            <div className="name">
              <p>Meat Plan #14</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$159.99</div>
            <div className="list">
              <p>FREE: 5 lb Bag of Potatos & Bag of Onions</p>
              <ul>
                <li>10 lbs. Chicken 1/4 Legs</li>
                <li>5 lbs. Chicken Drumsticks</li>
                <li>3 lbs. Chicken Wings</li>
                <li>4 lbs. Turkey Wings</li>
                <li>3 lbs. Center Cut Porkchops</li>
                <li>4 lbs. Fresh Neck Bones</li>
                <li>3 lbs. Smoked Neck Bones</li>
                <li>1 lb. Beef Franks</li>
                <li>3 lbs. Beef Stew</li>
                <li>3 lbs. Chuck Steak</li>
                <li>3 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
          <div className="special">
            <img src={Wholechicken} alt="" />
            <div className="name">
              <p>Meat Plan #15</p>
              <span>
                {isDetail ? (
                  <img src={Add} onClick={show} alt="" />
                ) : (
                  <img src={Remove} onClick={hide} alt="" />
                )}
              </span>
            </div>
            <div className="price">$199.99</div>
            <div className="list">
              <p>FREE: 5 lb Bag of Potatos & Bag of Onions</p>
              <ul>
                <li>10 lbs. Chicken 1/4 Legs</li>
                <li>3 lbs. Chicken Breast</li>
                <li>5 lbs. Chicken Drumsticks</li>
                <li>5 lbs. Chicken Wings</li>
                <li>One Whole Chicken</li>
                <li>5 lbs. Turkey Wings</li>
                <li>4 lbs. End Cut Pork Chops</li>
                <li>5 lbs. Fresh Neckbones</li>
                <li>5 lbs. Pig's Feet</li>
                <li>4 lbs. Pork Spare Ribs</li>
                <li>5 lbs. Smoked Neck Bones</li>
                <li>3 lbs. Beef Chuck Steak</li>
                <li>3 lbs. Beef Stew</li>
                <li>5 lbs. Beef Liver</li>
                <li>3 lbs. Ground Beef</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Specials;
