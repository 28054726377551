import React,{useState} from 'react';
import styled from '@emotion/styled';

import {Cow,Tbone,Chicken,Add,Remove} from '../../img'
import { Link } from 'react-router-dom';

import mq from './../../scripts/breakpoints';


const Container = styled.div`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            ${mq[1]}{
                padding: 10rem;
            }
        .text{
            text-align: center;
            width: 80%;
            margin: auto;
            h3{
                
                font-size: 1.5rem;
                color:#C13A3A;
                ${mq[1]}{
                    font-size: 2.5rem;
                }
            }
            p{
                ${mq[1]}{
                    font-size: 1.2rem;
                }
            }
        }
        .specials-container{
            width: 100%;
            ${mq[1]}{
                width: 50%;
                display: flex;
            }
        }
        .special{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            margin: auto;
            margin-bottom: 2.5rem;
            ${mq[1]}{
                margin-right: 1rem;
            }
            img{
                width: 60px;
            }
            .name{
                margin: .5rem 0;
                width: 100%;
                border: solid 1px #C13A3A;
                color: #C13A3A;
                font-weight: bold;
                font-family: 'Cabin', sans-serif;
                display: flex;
                justify-content: space-around;
                align-items: center;
                p{
                    margin-right: .8rem;
                    margin: .5rem 0 .5rem 0;
                }
                span img{
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .price{
                font-weight: bold;
                font-family: 'Alfa Slab One', sans-serif;
                font-size: 1.8rem;
                color: #C13A3A;

            }
            .list{
                border-bottom: solid #eee 1px;
                display: none;
                p{
                    font-family: 'Cabin'
                }
                ul{
                    li{
                        font-family: 'Arial';
                        font-size: .9rem;
                    }
                }
            }
        }
        button{
            margin-bottom: 1;
            width: 50%;
            margin-bottom: 1rem;
            font-size: .7rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            border: solid 2px #C13A3A ;
            color: #C13A3A;
            padding: .5rem 0rem;
            background-color: white; 
            border-radius: 1rem;
            ${mq[1]}{
                width: 15%;
                padding: .7rem ; 
            }
            a{
                list-style: none;
                color: #C13A3A;
                text-decoration: none;
            }
        }
        
    `;

const HomepageSpecial = () => {

    const [isDetail,setDetail] = useState(true)

    const show = (e) =>{
        
        const target = e.target
        .parentElement
        .parentElement
        .nextElementSibling
        .nextElementSibling

        
        target.style.display = 'block'
        target.classList.add('animate__animated', 'animate__fadeIn')
        setDetail(false)
    }

    const hide = (e) =>{
        const target = e.target
        .parentElement
        .parentElement
        .nextElementSibling
        .nextElementSibling
        
        target.style.display = 'none'
        setDetail(true)
    }

    return (
        <Container>
            <div className="text">
                <h3>Our Specials</h3>
                <p>Here are just a few of the specials we offer. 
                   Make sure to check out our other specials!
                </p>
            </div>
            <div className="specials-container">
            <div className="special">
            <img src={Cow} alt=""/>
                        <div className="name">
                            <p>Meat Plan #1</p> 
                            <span>
                                {isDetail
                                ?
                                    <img  src={Add} onClick={show} alt=""/>
                                    
                                :
                                    <img src={Remove} onClick={hide} alt=""/>
                                }
                                
                            </span>
                        </div>
                        <div className="price">
                            $39.99
                        </div>
                        <div className="list">
                            <p>FREE :
                               1 Dozen eggs
                            </p>
                            <ul>
                                <li>
                                    2 lbs. Chicken 1/4 
                                    Legs
                                </li>
                                <li>
                                    2 lbs. Chicken Drums
                                </li>
                                <li>
                                    2 lbs. Turkey Wings
                                </li>
                                <li>
                                    2 lbs. End Cut
                                    Pork Chops
                                </li>
                                <li>
                                    2 lbs. Pork Ribs
                                </li>
                                <li>
                                    2 lbs. Ground Beef
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="special">
                    <img src={Tbone} alt=""/>
                        <div className="name">
                            <p>Meat Plan #2</p>
                            <span>
                                {isDetail
                                ?
                                    <img  src={Add} onClick={show} alt=""/>
                                    
                                :
                                    <img src={Remove} onClick={hide} alt=""/>
                                }
                                
                            </span>
                        </div>
                        <div className="price">
                            $52.99
                        </div>
                        <div className="list">
                                <p>FREE: 1 Dozen
                                   Eggs
                                </p>
                                <ul>
                                    <li>4 lbs. Chicken 1/4 Legs</li>
                                    <li>2 lbs. Chicken Drums</li>
                                    <li>2 lbs. Chicken Wings</li>
                                    <li>2 lbs. Turkey Wings</li>
                                    <li>2 lbs. End Cut Porkchops</li>
                                    <li>1 lbs. Slab Bacon</li>
                                    <li>2 lbs. Smoked Neck Bones</li>
                                    <li>2 lbs. Ground Beef</li>
                                </ul>
                        </div>
                    </div>
                    <div className="special">
                    <img src={Chicken} alt=""/>
                        <div className="name">
                            <p>Meat Plan #3</p>
                            <span>
                                {isDetail
                                ?
                                    <img  src={Add} onClick={show} alt=""/>
                                    
                                :
                                    <img src={Remove} onClick={hide} alt=""/>
                                }
                                
                            </span>
                        </div>
                        <div className="price">
                            $73.99
                        </div>
                        <div className="list">
                                <p>FREE: 1 Dozen Eggs</p>
                                <ul>
                                    <li>3 lbs. Chicken 1/4 Legs</li>
                                    <li>2 lbs. Chicken Wings</li>
                                    <li>1 Whole Chicken</li>
                                    <li>3 lbs. Turkey Wings</li>
                                    <li>2 lbs. Beef Stew</li>
                                    <li>2 lbs. Beef Liver</li>
                                    <li>2 lbs. Chuck Steak</li>
                                    <li>2 lbs. Ground Beef</li>
                                </ul>
                                
                        </div>
                    </div>
            </div>
            <button>
                <Link to="/specials">VIEW MORE SPECIALS</Link>
            </button>
        </Container>
    );
};

export default HomepageSpecial;