import React from "react";
import homepage from "../data/homepage.json";
import {
  Banner,
  HomepageContent,
  HomepageSpecial,
} from "../components/Homepage";
import MetaData from "../components/MetaData";
import Alert from "../components/Alert";

const Homepage = () => {
  return (
    <div>
      {/*  TODO
        -comments Alert Component
     */}
      <Alert />
      <MetaData
        title={`Perez & Almonte - ${homepage.title}`}
        description={homepage.description}
      />
      <Banner />
      <HomepageContent />
      <HomepageSpecial />
    </div>
  );
};

export default Homepage;
