import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import React from 'react';

export default function Alert() {
  let history = useHistory();
  Swal.fire({
    title: 'Check our Specials, Great Deals and the Best Quality',
    showCancelButton: true,
    confirmButtonText: 'Go To Specials',
    color: '#C13A3A',
    confirmButtonColor: '#C13A3A',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      history.push('/specials');
    }
  });
  return <div></div>;
}
